export const useFeedback = () => {
  const { enabled, isExternal, to, params } = useAppConfig().settings.feedback

  const { host } = useRuntimeConfig().public

  /* Note that the url param keys can be customized (mostly for external usage) */
  const feedbackUrl = computed(() => {
    return `${to}?${params.app}=${host}&${params.route}=${useRoute().path}`
  })

  return {
    enabled, 
    feedbackUrl
  }
}
