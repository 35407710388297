<template>
  <NuxtLayout name="navigator" :crumbs="crumbs" :crumb-color="profile.color[type].button">
    <div 
      class="fixed z-0 top-[60px] sm:top-[75.5px] w-screen left-1/2 -ml-[50vw] h-80 bg-gradient-to-b to-white dark:to-gray-900"
      :class="profile.color[type].heroBg"
    />
    <div class="relative grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-y-8 md:gap-x-10 pt-[4.5rem]">
      <div class="h-fit-content relative z-20">
        <aside class="md:sticky md:top-[11rem] flex flex-col gap-4 z-10">
          <UCard
            :ui="{ring: profile.color[type].ring, body: { padding: 'sm:p-8'}}"
          >
            <UAvatar 
              :src="image" 
              :alt="title"
              :icon="icon"
              size="3xl"
              class="object-cover object-center bg-white dark:bg-gray-700 rounded-full w-32 h-32 sm:w-36 sm:h-36 absolute -top-16 sm:-top-[4.5rem] left-1/2 -translate-x-1/2 ring-[12px] ring-white/50 dark:ring-gray-800" 
              :ui="{
                icon: { base: profile.color[type].text }
              }"
            />
            <div class="w-full pt-20">
              <h1 class="text-base">{{ title }}</h1>
              <slot name="header" />
            </div>

            <div class="pt-4">
              <p class="text-sm font-bold text-gray-600 dark:text-gray-300">Deel dit profiel</p>
              <SocialShare :title="title" :color="profile.color[type].button" />
            </div>
            
          </UCard>
          <UButton v-if="externalUrl" :to="externalUrl" :label="externalLabel" :color="profile.color[type].button" variant="soft" size="lg" icon="i-heroicons-arrow-up-right-20-solid" trailing block external target="_blank" />
          <UButtonGroup v-if="!isProd && props.slug" size="lg">
            <UButton :to="v1Url" label="bekijk in oude navigator" class="grow text-center grid place-items-center" color="white" variant="solid" trailing external target="_blank" />
            <UTooltip v-if="isSupported || !isMounted" text="kopieer naar klembord">
              <UButton  :icon="!copied ? 'i-heroicons-clipboard-document-20-solid' : 'i-heroicons-check-16-solid'" color="white" variant="solid" @click="copy(v1Url)" />
            </UTooltip>
            
          </UButtonGroup>
          
        </aside>
      </div>
      <div class="md:col-span-2 flex flex-col gap-8  z-10">
        <UCard :ui="{ring: profile.color[type].ring, body: { padding: 'py-6 sm:p-8' }}">
          <slot name="body" />
        </UCard>
        <UCard 
          v-if="coordinates" 
          :ui="{body: { padding: 'p-0 sm:p-0'}, ring: 'ring-gray-200 dark:ring-gray-700'}"
        >
          <ClientOnly>
            <div class="dark:opacity-60 transition-all">
              <Map 
                :center="coordinates"
                :markers="markers"
                :zoom-control="true"
                :withCurrentLocation="false"
                :height="300" 
                :zoom="12"
              />
            </div>
            
            <template #fallback>
              <USkeleton class="rounded-lg h-[300px]" />
            </template>
          </ClientOnly>
          
        </UCard>
        <slot name="wildcard" />
        <UAlert 
          v-if="enabled"
          color="aux2" 
          variant="soft" 
          icon="i-heroicons-information-circle-20-solid" 
          :actions="[{label: 'maak een melding', to: feedbackUrl, color: 'aux2', variant: 'solid', target: '_blank'}]"
        >
          <template #description>
            <p class="text-xs text-aux2-900 dark:text-aux2-300">Bevat dit profiel informatie die incompleet is, of niet meer up to date? Laat het ons team weten en dan passen wij de pagina aan.</p>
          </template>
        </UAlert>
        
          <slot name="footer" />
      </div>
    </div>
    <slot />
  </NuxtLayout>
</template>

<script lang="ts" setup>

const props = withDefaults(defineProps<{
  crumbs?: {
    label: string,
    icon?: string
    to?: string
  }[]
  coordinates?: { lat: number, lng: number }
  type: 'opleiding' | 'onderwijsinstelling' | 'regioloket' | 'school'
  image?: string
  title?: string
  slug?: string
  externalUrl?: string
  externalLabel?: string
}>(), { externalLabel: 'naar website' })

const { profile } = useAppConfig().components

const { isMounted } = useUiState()

const { isProd } = useRuntimeConfig().public.mode

const v1Url = computed(() => {
  let url = 'https://onderwijsloket.com/onderwijsnavigator/'
  if (props.type === 'opleiding') url += 'opleidingen/'
  else url += (props.type + '/')
  url += (props.slug + '/')
  return url
})

const { copy, copied, isSupported } = useClipboard({ source: v1Url })

const { enabled, feedbackUrl } = useFeedback()

const { icons } = useConstants()

const icon = computed(() => {
  if (props.type === 'onderwijsinstelling') return icons.opleider
  return icons[props.type]
})

import type { Marker } from '~~/types/map'
const markers = computed(() => ([
  {
    type: props.type, 
    ...props.coordinates,
    color: 'secondary',
    variant: 'solid',
    size: 'xl'
  } as Marker
]))

</script>

<style lang="postcss">
.profile-body {
  p {
    @apply mb-2
  }
}
</style>